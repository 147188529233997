import ProgressionImg from "../../images/who-we-are/progresion.png";
import WorkoutImg from "../../images/who-we-are/workout.png";
import NutritionImg from "../../images/who-we-are/nutritions.png";

export const cardList = [
  {
    img: ProgressionImg,
    title: "Progression",
    description:
      "We gradually help you to improve in physical fitness overtime through consistent excercise and proper nutrition.",
  },
  {
    img: WorkoutImg,
    title: "Workout",
    description:
      "We will help you to increase the intensity duration or frequency of your workouts to continually challenge your body.",
  },
  {
    img: NutritionImg,
    title: "Nutritions",
    description:
      "With our knowledge we can gradually help your fitness goals with a proper balanced diet which is rich in proteins, carbs, fats and other essentials.",
  },
];
